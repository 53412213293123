<template>
  <div class="wrap">
    <Navbar :title="$t('page.financialRecord')" />
    <FinancialRecord />
  </div>
</template>

<script>
import { Icon, Toast, List } from "vant";
import { $post, $get } from "@/utils/request";
import FinancialRecord from "./components/financialRecordCom.vue";
import Navbar from "@/components/JNav"
export default {
  components: { [Icon.name]: Icon, [List.name]: List,FinancialRecord, Navbar },
  data() {
    return {
      list: [],
      loading: true,
      finished: false,
      currentPage: 1,
      firstGet: true,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      try {
        const res = await $get("/yuebao/transfers", { page: this.currentPage });
        const { data, ret, msg } = res.data;
        this.loading = false;
        if (ret == 1) {
          if (!data.hasMorePages) {
            this.finished = true;
          }
          this.currentPage++;
          if (this.firstGet) {
            this.list = data.items;
            this.firstGet = false;
            return;
          }
          this.list = [...this.list, data.items];
          return;
        }
        Toast(msg);
      } catch (error) {}
    },
    getStatusText(action) {
      switch (action) {
        case 1:
          return this.$t("status.into");
        case 2:
          return this.$t("status.out");
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  min-height: 100vh;
  background: #f0ebef;
  --nav-background: #ff0000;
  --theme: #ff0000;
  /deep/ .van-nav-bar {
    background: var(--nav-background);
  }
  /deep/ .van-nav-bar__title {
    color: var(--textColor) !important;
  }
}
.list_item {
  margin: calc(9rem / 16) calc(6rem / 16) 0;
  background: #fff;
  border-radius: 4px;
  padding: calc(10rem / 16) 0 calc(21rem / 16) calc(15rem / 16);
  .title {
    color: var(--theme);
    font-size: calc(18rem / 16);
    margin-bottom: calc(12rem / 16);
  }
  .row {
    display: flex;
    margin-top: calc(9rem / 16);
    &:nth-child(1) {
      margin-top: 0;
    }
    > div {
      width: 50%;
    }
    .left {
      font-size: calc(14rem / 16);
      color: #707070;
    }
    .right {
      font-size: calc(16rem / 16);
    }
  }
}
</style>